@import '../styles/config/config.scss';

.header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100px;
  z-index: 99;
  background-color: white;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.2);

  @include media-query($medium-down) {
    background: white;
    box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.2);
  }

  &__wrapper {
    position: relative;
    padding: 0 24px;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 1fr;

    @include media-query($large-up) {
      &[data-nav-position='center'] {
        grid-template-columns: 10% 80% 10%;
      }
    }
  }
}
