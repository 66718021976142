.h1Text {
  font-weight: 500 !important;
  font-size: 20px !important;
  color: black;
  text-align: center;
}

.text {
  font-size: 18px !important;
  color: black;
  font-weight: 400 !important;
  line-height: 3rem !important;
  text-align: center;
}

.containerAbout {
  padding: 100;
  background-color: white;
  /* text-align: center; */
}
@media (min-device-width: 320px) and (max-device-width: 812px) {
  .containerAbout {
    padding: 60 20 0;
    margin-right: 0;
    margin-left: 0;
    /* padding-top: 10px; */
    /* word-wrap: break-word; */
  }
  .h1Text {
    /* font-size: 50px !important; */
    color: black;
    font-weight: bold;
  }

  .text {
    font-weight: 500;
    font-size: 16px !important;
    color: black;
    font-weight: 300;
    line-height: 3rem !important;
    text-align: center;
  }
}

/* @media (min-width: 768px) {
  .containerAbout {
    padding: 0;
    margin-right: 0;
    margin-left: 0;
  }
} */
