.navbar-nav > li {
  padding-left: 10px;
  padding-right: 10px;
}
#menu {
  display: flex;
  justify-content: center;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.3) !important;
}
/* a {
  color: black !important;
  font-size: 20px !important;
  font-weight: 600 !important;
} */
/*
a:hover,
a:focus {
  text-decoration: none;
  color: #48a1c6;
} */

.menu,
.submenu {
  list-style-type: none;
}
.menu {
  background-color: #fff;
}
.logo {
  font-size: 20px;
  padding: 7.5px 10px 7.5px 0;
}
.logo:hover {
  cursor: pointer;
}
.item {
  padding: 10px;
}
.item.button {
  padding: 9px 5px;
}
.item:not(.button) a:hover,
.item a:hover::after {
  color: #ccc;
}

/*  */

.menu {
  display: flex !important;
  /* justify-content: space-between; */
  align-items: center;
}
.menu li a {
  display: block;
}
.menu li.subitem a {
  padding: 15px;
}
.toggle {
  order: 1;
  font-size: 20px;
}
.item.button {
  order: 2;
}
.item {
  order: 3;
  width: 100%;
  text-align: center;
  display: none;
}
.active .item {
  display: block;
}
.button.secondary {
  /* divider between buttons and menu links */
  border-bottom: 1px #444 solid;
}

@media (max-width: 1000px) {
  img {
    justify-content: flex-start;
    align-self: flex-start;
  }
}
