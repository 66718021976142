.teamContainer {
  width: 100%;
  columns: 2;
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 0 10%;
  /* flex-direction: column; */
  /* align-items: center;
  justify-content: center; */
  /* background-color: red; */
}
.section-title {
  padding: 0 100px;
}
/* Team Section */

#team {
  background-color: white;
  padding: 60px 0;
}

#team .team-img {
  /* width: 250px; */
  max-height: 100%;
  max-width: 100%;
  /* border-radius: 50%; */
}
.border {
  padding: 5%;
  border: 1px solid black;
}
#team .thumbnail {
  padding: 10px 120px;
  color: #888;
  border-width: 2px;
}
.caption {
  align-items: center;
  padding: 5% 0;
}

.section-item-team {
  padding: 0 5%;
  /* flex: 1; */
  background-color: white;
}

.section-col-team {
  flex-direction: column;
  flex-grow: 1;
  display: flex;
  align-items: center;
  background-color: white;
}

.section-col .thumbnail {
  width: 100%;
  background: transparent;
  border: 0;
}

.caption h4 {
  font-size: 25px;
  margin: 5px 0;
  text-transform: uppercase;
  text-align: center;
}
.caption p {
  font-style: italic;
  font-size: 18px;
  text-align: center;
}
.description {
  text-align: center;
  font-size: 16px;
}

.caption hr {
  background-color: black;
  display: block;
  border-top: 1px solid #000;
  left: 0px;
  align-self: center;
  width: 100%;
  margin: 0;
}

/* For ipad mini */
@media only screen and (min-width: 500px) and (max-width: 800px) {
}

@media only screen and (max-width: 500px) {
  /* For mobile phones: */

  #team {
    background-color: white;
    padding: 30px 0;
  }
  .section-title {
    padding: 20px;
  }
  .h1Text {
    font-size: 40px !important;
    margin: 0;
  }

  .teamContainer {
    width: 100%;
    columns: 1;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
  }
  .section-col {
    align-items: center;
  }
  .caption {
    padding: 10% 0;
  }
  .caption h4 {
    text-align: center;
    /* align-self: center; */
  }
  .caption p {
    text-align: center;
  }
  .description {
    text-align: center;
    font-size: 16px;
  }
}
