.main-content {
  height: 500px;
  display: flex;
  flex-direction: row;
  flex: 1;
  padding-top: 100px;
}
.main-content-ss {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 80px;
}
.main-content-item {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  line-height: 3em;
  transition: all ease-in 0.3s;
}
.item-list-link {
  font-size: 14px !important;
}

a:hover {
  color: #608dfd;
  cursor: pointer;
  /* text-decoration: underline; */
}

ul {
  text-decoration: none;
}

@media only screen and (max-width: 500px) {
  .main-content {
    height: 600px;
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-bottom: 80px;
  }
  .main-content-ss {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 0px;
  }
  .main-content-item {
    display: flex;
    flex-direction: column;
    flex: 1;
    line-height: 3em;
  }
}
.img-logo {
  width: 120px;
  height: 80px;
}
