.section-title-contact {
  color: black;
}

.contact-content {
  background-color: #48a1c6;
  width: 100%;
  display: flex;
  height: 500px;
  flex: 1;
}

.sub-action {
  color: white;
}

.contact-content p {
  font-size: 18px;
}
/* CSS */
.button-66 {
  background-color: #0a6bff;
  border-radius: 4px;
  border: 0;
  box-shadow: rgba(1, 60, 136, 0.5) 0 -1px 3px 0 inset,
    rgba(0, 44, 97, 0.1) 0 3px 6px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inherit;
  font-family: 'Space Grotesk', -apple-system, system-ui, 'Segoe UI', Roboto,
    Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  margin: 0;
  min-height: 56px;
  min-width: 120px;
  padding: 16px 20px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  transition: all 0.2s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.btn:hover {
  background-color: #065dd8;
  transform: translateY(-2px);
}
.btn {
  font-weight: bold;
  letter-spacing: 1px;
  border-radius: 0px;
}

.sub-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  flex: 1;
  height: 900px;
}

.sub {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.form-input {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.sub {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 50px;
  width: 100%;
}
.sub-content {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  flex: 1;
}

.form-input-box {
  width: 300px !important;
  height: 50px;
}

@media only screen and (min-width: 500px) and (max-width: 800px) {
  .form-input-box {
    width: 500px;
    height: 50px;
  }
}

@media only screen and (max-width: 500px) {
  .sub {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
  }
  .sub-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    flex: 1;
  }
  .form-input {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .contact-content {
    height: 400px !important;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }
  .form-input-box {
    width: 280px;
    height: 50px;
  }
  .btn {
    width: 300px !important;
    margin-top: 10px;
  }
}

/* .form-input-box {
  height: 50px;
  width: 500px;
} */
