@import '../styles/config/config.scss';

.nav {
  font-size: 20px;
  position: relative;

  [data-nav-position='right'] &,
  [data-nav-position='center'] & {
    @include media-query($medium-down) {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background: white;
      pointer-events: none;
      padding: 24px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 9;
      opacity: 0;

      // @supports (backdrop-filter: blur(10px)) {
      //   background: rgba(white, 0.6);
      //   backdrop-filter: blur(10px);
      // }

      &.open {
        pointer-events: auto;
      }
    }
  }

  [data-nav-position='overlay'] & {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: white;
    pointer-events: none;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(10px);
    z-index: 9;
    opacity: 0;

    // @supports (backdrop-filter: blur(10px)) {
    //   background: rgba(white, 0.6);
    //   backdrop-filter: blur(10px);
    // }

    &.open {
      pointer-events: auto;
    }
  }

  @include media-query($large-up) {
    [data-nav-position='right'] &,
    [data-nav-position='center'] & {
      place-self: center;
      pointer-events: auto;
    }

    [data-nav-position='center'] & {
      grid-column: 2/3;
    }

    [data-nav-position='right'] & {
      justify-self: end;
    }
  }

  &__item {
    font-weight: 700;
    font-size: 18px;
    color: black;
    letter-spacing: 2.22px;
    text-align: left;
    text-transform: uppercase;
    margin: 24px;
    text-decoration: none;

    position: relative;
    text-decoration: none;
    display: inline-block;
  }
  &__item:hover {
    border-bottom: 1px solid #48a1c6;
    color: #48a1c6;
    transition: width 0.2s;
  }
  &__item:after {
    display: block;
    content: '';
    bottom: -5px;
    height: 4px;
    border-bottom: solid 1px #48a1c6;
    transform: scaleX(0);
    transition: transform 300ms ease-in-out;
    transform-origin: 100% 50%;
  }
  &__item:hover:after {
    transform: scaleX(1);
    transform-origin: 0 50%;
  }
}
