.logo {
  grid-column: 1/2;
  align-self: center;
  z-index: 10;
  svg {
    height: 42px;
    width: auto;
  }
}
.logoImg {
  height: 70px;
  max-width: 100%;
}
@media only screen and (max-width: 500px) {
  .logoImg {
    height: 70px;
    width: 70%;
  }
}
