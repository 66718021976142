.servicesTitle {
  margin: 50px 0;
}
.h1Text {
  font-weight: 500 !important;
  font-size: 70px !important;
  color: black;
  text-align: center;
}
.section-titleH1 {
  font-weight: 600 !important;
  font-size: 40px !important;
  color: black;
  /* align-self: center; */
  text-align: center !important;
  text-transform: capitalize !important;
}
.section-text {
  font-weight: 500;
  font-size: 18px !important;
  line-height: 3rem !important;
  color: black;
  text-align: center;
  word-wrap: break-word;
  padding: 0 50px;
  /* margin: 50px; */
}
.section-content {
  margin-top: 5%;
}
.section-item {
  background: #f3f3f3;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 1;
}
.section-col {
  flex-direction: column;
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  width: 50%;
  align-items: center;
}
.mkt-img {
  width: 100%;
  height: 550px;
}
.mkt-img:hover {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}
.mkt-img {
  transition: all ease-in 0.3s;
}
.mkt-img:hover {
  transform: scale(1.05);
}
.orderList {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  color: black;
  width: 80%;
  font-size: 16px;
  line-height: 3rem !important;
}

/* For ipad mini */
@media only screen and (min-width: 500px) and (max-width: 800px) {
  .section-item {
    flex-direction: column;
    display: flex;
    flex-grow: 1;
    margin: 10px;
  }

  .section-item {
    flex-direction: column;
    display: flex;
    width: 100%;
  }

  .section-content {
    order: 1;
  }
  .section-img {
    order: 0;
  }
  .section-titleH1 {
    font-weight: bold !important;
    font-size: 29px !important;
    /* margin-top: ; */
    /* color: red; */
  }
  .section-text {
    padding-left: 0px;
    padding-right: 0px;

    font-size: 16px !important;
    color: black;
    font-weight: 300;
    line-height: 3rem !important;
  }

  .mkt-img {
    height: 250px;
    object-fit: cover;
  }
}

@media only screen and (max-width: 500px) {
  /* For mobile phones: */
  .servicesTitle {
    margin: 30px 0 0 0;
  }
  #services {
    display: flex;
    flex-direction: column;
  }
  .h1Text {
    font-size: 50px !important;
    padding: 20px;
  }
  .section-item {
    flex-direction: column;
    display: flex;
    flex-grow: 1;
    width: 100%;
    padding-top: 40px;
    margin: 0px;
  }

  .section-col {
    flex-direction: column;
    display: flex;
    width: 100%;
    align-content: center;
    flex-grow: 1;
    /* padding: 5 0px; */
  }

  .section-content {
    order: 1;
  }
  .section-img {
    order: 0;
  }
  .section-titleH1 {
    padding: 0 10px;
    font-weight: bold !important;
    font-size: 29px !important;
  }
  .section-text {
    font-size: 16px !important;
    color: black;
    font-weight: 400 !important;
    line-height: 3rem !important;
    text-align: center;
    padding: 0 20px;
    /* background-color: red; */
  }
  .mkt-img {
    height: 250px;
    width: 100%;
    object-fit: cover;
  }
  .orderList {
    width: '80%';
    align-self: center;
  }
}
