.each-slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  /* height: 350px; */
}
.bannerHeader {
  line-height: 4rem;
}
.each-slide img {
  /* padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center; */
  height: 100vh;
  width: 100%;
}

.featured-slide {
  /* height: 50vh; */
  /* margin: 0 auto; */
  /* width: 20vw; */
  /* height: auto; */
  /* object-fit: cover; */
  height: 100vh;
  width: 100%;
  /* background-color: red; */
}
.banner {
  background: rgba(72, 161, 198, 0.9);
  height: 85vh;
  width: 85vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  text-align: center;

  /* move the item to center
   */
  align-self: center;
  position: absolute;
  top: 57%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* left: 50%; */
}
.banner h1 {
  color: white;
  font-size: 60px !important;
  font-weight: 600 !important;
  padding: 0 40px;
}
.banner p {
  color: white;
  font-size: 19px !important;
  line-height: 3rem !important;
  padding: 0 50px;
}
p span {
  font-weight: bold;
}
span a {
  color: white;
  font-weight: bold;
  font-size: 25px;
}
@media only screen and (max-width: 500px) {
  .banner {
    height: 48vh;
    width: 100%;
  }
  .bannerHeader {
    line-height: 0;
  }

  .banner h1 {
    font-size: 2.4em !important;
    padding: 0 30px;
  }
  .banner p {
    color: white;
    font-size: 13px !important;
    line-height: 2rem !important;
    padding: 0 30px;
  }
  .banner h3 {
    font-size: 18px;
    color: white;
  }
  span a {
    font-size: 15px;
  }
  .each-slide img {
    height: 100vh;
    width: 100%;
    object-fit: cover;
  }
}

@media only screen and (max-width: 380px) {
  .bannerHeader {
    line-height: 0;
  }
  .banner {
    height: 60%;
    width: 100%;
  }
  .banner h1 {
    font-size: 2.5em !important;
    padding: 0 30px;
  }
  .banner p {
    color: white;
    font-size: 13px !important;
    line-height: 2rem !important;
    padding: 0 30px;
  }
}
