html * {
  /* font-family: "Montserrat", sans-serif !important;
  font-family: "Noto Serif", serif !important;
  font-family: "Oswald", sans-serif !important; */
  font-family: "Poppins" !important;
}
body {
  margin: 0;
  height: 100%;
  width: 100%;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* section img {
  object-fit: cover;
} */

#navHeader {
  /* display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; */
}
.hamBtn {
  display: none;
}
@media (max-width: 375px) {
  .hamBtn {
    height: 70px;
    width: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
@media (min-width: 768px) {
  #navHeader {
    width: 750px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media (min-width: 992px) {
  #navHeader {
    width: 970px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media (min-width: 1200px) {
  #navHeader {
    width: 1170px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
